import React from 'react';
import MetricsTable from './MetricsTable';
import spendMetrics from '../metric_definitions/spend.yaml'
import adRevenueMetrics from '../metric_definitions/ad_revenue.yaml'
import skAdNetworkMetrics from '../metric_definitions/sk_ad_network.yaml'

export default class MetricDefinitions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {searchText: ''};

    this.handleSearch = this.handleSearch.bind(this)
  }

  handleSearch(event) {
    this.setState({searchText: event.target.value})
  }

  render() {
    return(
      <div id='content' className='metrics'>
        <div className='metrics-content'>
          <div className='metrics-title'>
            <div>
              <h1>Metric Definitions</h1>
            </div>
            <input type='text'
              value={this.state.searchText}
              onChange={this.handleSearch}
              placeholder='Search Metric Definitions...' />
          </div>
          <div className='metrics-content-header'>
            <ul>
              <li><a href='#spend'>Spend Metrics</a></li>
              <li><a href='#ad_revenue'>Ad Revenue Metrics</a></li>
              <li><a href='#sk_ad_network'>SK Ad Network Metrics</a></li>
            </ul>
          </div>
        </div>
        <MetricsTable
          id="spend"
          title="Spend Metrics"
          endpoint="/reporting-metrics-api#tag/Reporting-Metrics/paths/~1reports~1spend/get"
          endpoint_name="Spend Reporting Data"
          metrics={spendMetrics}
          searchText={this.state.searchText} />
        <MetricsTable
          id="ad_revenue"
          title="Ad Revenue Metrics"
          endpoint="/reporting-metrics-api#tag/Reporting-Metrics/paths/~1reports~1ad_revenue/get"
          endpoint_name="Ad Revenue Reporting Data"
          metrics={adRevenueMetrics}
          searchText={this.state.searchText} />
        <MetricsTable
          id="sk_ad_network"
          title="SK Ad Network Metrics"
          endpoint="/reporting-metrics-api#tag/Reporting-Metrics/paths/~1reports~1sk_ad_network/get"
          endpoint_name="SK Ad Network Reporting Data"
          metrics={skAdNetworkMetrics}
          searchText={this.state.searchText} />
      </div>
    )
  }
}
