import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect } from 'react-router-dom';
import './App.scss';
import CampaignManagementApi from './components/CampaignManagementApi';
import ReportingMetricsApi from './components/ReportingMetricsApi';
import MetricDefinitions from './components/MetricDefinitions'
import ReleaseNotes from './components/ReleaseNotes';
import Dropdown from 'react-dropdown';

class App extends React.Component {
  state = {
    apiVersion: '2.0.1',
    apiVersions: [
      {
        value: '2.0.1',
        label: 'v2.0.1 (Latest)'
      },
      {
        value: '2.0.0',
        label: 'v2.0.0'
      },
      {
        value: '1.0.1',
        label: 'v1.0.1'
      },
      {
        value: '1.0.0',
        label: 'v1.0.0'
      }
    ]
  }

  handleDropdownChange = (option) => {
    this.setState({ apiVersion: option.value })
  }

  render() {
    return (
      <Router>
        <div className='apiDocs'>
          <nav className='apiDocs-nav'>
            <div className='apiDocs-nav-logo'>
              <Link to='/'>
                <img src='/tenjin.png' alt='Tenjin Logo' />
              </Link>
            </div>
            <div className='apiDocs-nav-content'>
              <ul className='apiDocs-nav-content-items'>
                <li className='apiDocs-nav-content-items-item'>
                  <NavLink to='/campaign-management-api' activeClassName='active'>
                    Campaign Management API
                  </NavLink>
                </li>
                <li className='apiDocs-nav-content-items-item'>
                  <NavLink to='/reporting-metrics-api' activeClassName='active'>
                    Reporting Metrics API
                  </NavLink>
                </li>
                <li className='apiDocs-nav-content-items-item'>
                  <NavLink to='/metric_definitions' activeClassName='active'>
                    Metric Definitions
                  </NavLink>
                </li>
                <li className='apiDocs-nav-content-items-item'>
                  <NavLink to='/release_notes' activeClassName='active'>
                    Release Notes
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className='apiDocs-nav-version'>
              <div className='apiDocs-nav-version-label'>
                API Version
              </div>
              <Dropdown
                className='apiDocs-nav-version-dropdown'
                options={this.state.apiVersions}
                onChange={this.handleDropdownChange}
                value={this.state.apiVersion}
                placeholder={`Version ${this.state.apiVersion}`}
              />
            </div>
          </nav>
          <main className='apiDocs-main'>
            <Switch>
              <Route
                path='/campaign-management-api'
                render={(props) => <CampaignManagementApi {...props} version={this.state.apiVersion} />}
              />
              <Route
                path='/reporting-metrics-api'
                render={(props) => <ReportingMetricsApi {...props} version={this.state.apiVersion} />}
              />
              <Route path='/metric_definitions' component={MetricDefinitions} />
              <Route path='/release_notes' component={ReleaseNotes} />
              <Redirect from='/' to='/campaign-management-api' exact />
            </Switch>
          </main>
        </div>
      </Router>
    );
  }
}

export default App;
