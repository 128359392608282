import React from 'react'
import MetricsTableRow from './MetricsTableRow'
import './MetricsTable.scss'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom';

export default class MetricsTable extends React.Component {
  constructor(props) {
    super(props)

    const list = Object.keys(props.metrics).sort().map(metric => {
      const id                    = metric
      const { name, description } = props.metrics[metric]

      return { id, name, description }
    })

    this.state = {
      list:       new Fuse(list, {
        keys:       ['id', 'name'],
        threshold:  0.3
      })
    }
  }

  filterResults() {
    if (this.props.searchText) {
      return this.state.list.search(this.props.searchText)
    }

    return this.state.list.list
  }

  tableRows() {
    return this.filterResults().map(metric => {
      const { id, name, description } = metric
      return <MetricsTableRow key={this.props.id + id} attribute={id} name={name} desc={description} />
    })
  }

  metricsTable() {
    return (
      <table className='metrics-content-table'>
        <thead>
          <tr>
            <th>API Attribute</th>
            <th>Friendly Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>{this.tableRows()}</tbody>
      </table>
    )
  }

  render() {
    return (
      <div className='metrics-content'>
        <div className='metrics-content-header'>
          <h2 id={this.props.id}>{this.props.title}</h2>
          <p>
            Metrics available at the{' '}
            <Link to={this.props.endpoint}>{this.props.endpoint_name}</Link>{' '}
            endpoint.
          </p>
        </div>
        {this.metricsTable()}
      </div>
    )
  }
}
