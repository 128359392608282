export default {
  scrollYOffset: 'nav.apiDocs-nav',
  theme: {
    colors: {
      primary: {
        main: '#265479'
      }
    },
    typography: {
      links: {
        color: '#5994c8'
      }
    }
  }
}
