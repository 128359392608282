import React from 'react';
import { RedocStandalone } from 'redoc';
import v100 from '../openapi/1.0.0/campaignManagementApi.yaml';
import v101 from '../openapi/1.0.1/campaignManagementApi.yaml';
import v200 from '../openapi/2.0.0/campaignManagementApi.yaml';
import v201 from '../openapi/2.0.1/campaignManagementApi.yaml';
import theme from '../settings/theme';

const VERSION_TO_OPENAPI_SPEC = {
  '1.0.0': v100,
  '1.0.1': v101,
  '2.0.0': v200,
  '2.0.1': v201
}

export default class CampaignManagementApi extends React.Component {
  render() {
    const spec = VERSION_TO_OPENAPI_SPEC[this.props.version]

    return <RedocStandalone spec={spec} options={theme} />
  }
}
