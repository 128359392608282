import React from 'react';

export default class MetricsTableRow extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.attribute}</td>
        <td>{this.props.name}</td>
        <td>{this.props.desc}</td>
      </tr>
    )
  }
}
