import React from 'react';
import renderHTML from 'react-render-html';
import releaseNotes from '../release_notes/content.yaml'
import './ReleaseNotes.scss'

export default class ReleaseNotes extends React.Component {
  render() {
    return (
      <div id='content' className='releaseNotes'>
        <div>
          <h1>
            Release Notes
          </h1>
        </div>
        {releaseNotes.map((releaseNote, i) => {
          return (
            <div className='releaseNotes-note' key={releaseNote.date}>
              <div className='releaseNotes-note-header'>
                <h3>{`Version: ${releaseNote.version}`}</h3>
                <div className='releaseNotes-note-header-date'>{releaseNote.date}</div>
              </div>
              {renderHTML(releaseNote.notes)}

              {i !== (releaseNotes.length - 1) && <hr />}
            </div>
          )
        })}
      </div>
    )
  }
}
